<template>
  <div
    class="SimpleVerticalCarousel"
    :class="{ isAnimating }">
    <div
      ref="slideContainer"
      class="slideContainer">
      <transition
        :css="false"
        @before-enter="beforeEnter"
        @before-leave="beforeLeave"
        @enter="enter"
        @leave="leave">
        <component
          :is="currentItem.component"
          v-if="currentItem"
          :key="internalIndex"
          class="slide"
          v-bind="currentItem.props"
          :index="internalIndex" />
      </transition>
    </div>
    <div
      class="nextButton --global-clickable"
      :class="{ disabled: internalIndex === items.length - 1 }"
      @click="next()">
      <svgicon
        class="nextButtonIcon"
        icon="chevron-down" />
    </div>
    <div
      class="prevButton --global-clickable"
      :class="{ disabled: internalIndex === 0 }"
      @click="prev()">
      <svgicon
        class="prevButtonIcon"
        icon="chevron-down" />
    </div>
  </div>
</template>

<script>
import { TimelineLite } from 'gsap';

export default {
    props: {
        index: {
            type: Number,
            reqiured: true
        },
        items: {
            type: Array
        },
        transitionDuration: {
            type: Number,
            default: 1
        },
        showPlaceholders: {
            type: Boolean,
            default: false
        },
        sidePadding: {
            type: String,
            default: '0em'
        },
        reversed: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            direction: 0,
            isAnimating: false,
            internalIndex: this.index,
            manual: false
        };
    },
    computed: {
        currentItem() {
            return this.items[this.internalIndex];
        }
    },
    watch: {
        index(newIndex, oldIndex) {
            this.manual = false;
            this.gotoCard(newIndex);
        }
    },
    methods: {
        prev() {
            this.$emit('request-index', this.index - 1);
        },
        next() {
            this.$emit('request-index', this.index + 1);
        },
        gotoCard(index, manual = false) {
            if (this.internalIndex < index && index <= this.items.length - 1) {
                this.direction = this.reversed ? -1 : 1;
            } else if (this.internalIndex > index && index >= 0) {
                this.direction = this.reversed ? 1 : -1;
            } else {
                this.direction = 0;
            }
            if (this.direction) {
                this.manual = manual;
                if (this.isAnimating) {
                    this.tl.time(0);
                    this.tl.kill();
                    if (this.enterDone) {
                        this.enterDone();
                    }
                    if (this.leaveDone) {
                        this.leaveDone();
                    }
                    this.isAnimating = false;
                }
                this.internalIndex = index;
            }
        },
        beforeEnter: function (el) {
            this.elementEntering = el;
        },
        beforeLeave: function (el) {
            this.elementLeaving = el;
        },
        enter: function (el, done) {
            this.enterDone = done;
            this.setupAnimation();
        },
        leave: function (el, done) {
            this.leaveDone = done;
        },

        setupAnimation() {
            const h = this.elementEntering.clientHeight;
            const gapH = 0;

            let tl = new TimelineLite({
                onComplete: () => {
                    if (this.manual) {
                        return;
                    }
                    this.isAnimating = false;
                    this.direction = 0;
                    this.enterDone();
                    this.leaveDone();
                    this.enterDone = null;
                    this.leaveDone = null;
                    this.$emit('request-index', this.internalIndex);
                }
            });

            tl.fromTo(
                this.elementEntering,
                this.transitionDuration,
                {
                    opacity: this.showPlaceholder ? 0.2 : 1,
                    yPercent: (100 + (gapH * 100) / h) * this.direction,
                    scale: 1
                },
                {
                    opacity: 1,
                    yPercent: 0,
                    scale: 1
                },
                0
            );

            tl.fromTo(
                this.elementLeaving,
                this.transitionDuration,
                {
                    yPercent: 0,
                    scale: 1
                },
                {
                    opacity: this.showPlaceholder ? 0.2 : 1,
                    yPercent: -(100 + (gapH * 100) / h) * this.direction,
                    scale: 1
                },
                0
            );

            this.tl = tl;
            this.isAnimating = true;
            if (this.manual) {
                tl.pause();
                tl.seek(0);
            }
        }
    }
};
</script>
<style lang="scss" scoped>
$padding: 2em;
.SimpleVerticalCarousel {
    position: relative;
    display: flex;

    &.isAnimating {
        .prevButton,
        .nextButton {
            opacity: 0;
            // NOTE: can still click buttons
        }
    }
}
.slideContainer {
    position: relative;
    width: 100%;
}
.slide {
    $padding: 0;
    position: absolute;
    top: $padding;
    right: $padding;
    bottom: $padding;
    left: $padding;
    backface-visibility: hidden;
}
.leftSide,
.rightSide {
    min-width: $padding;
    flex: 1;
}

.prevButton,
.nextButton {
    position: absolute;
    left: 50%;
    align-self: center;
    padding: 0.5em 1em;
    transform: translateX(-50%);
    transition: opacity 0.5s;

    &.disabled {
        opacity: 0;
        cursor: default;
    }
}
.prevButton {
    padding-bottom: 1em;
    bottom: 0;
}
.nextButton {
    padding-top: 1em;
    top: 0;
}
.prevButtonIcon,
.nextButtonIcon {
    $size: 1em;
    width: $size;
    height: $size;
    fill: rgba(white, 0.5);
}
.nextButtonIcon {
    transform: rotate(180deg);
}
</style>
