<template>
  <div class="SuperGroupPage">
    <component
      :is="config.SuperGroupHeader"
      v-if="config.SuperGroupHeader"
      :super-group-index="index"
      class="header" />
    <div
      v-else
      class="header--empty" />
    <div class="body">
      <div class="spacerTop" />
      <template v-for="(group, i) of groups">
        <div
          v-if="i > 0"
          :key="`${i}-vline`"
          class="verticalLine" />
        <Group
          :key="i"
          v-bind="group"
          @click="gotoMemorize(group)" />
      </template>
      <div class="spacerBottom" />
      <!-- <SpeakBubble text="This is where your journey begins! Master the memory systems and become a black belt in memory with games"/> -->
    </div>
  </div>
</template>

<script>
import Group from '../components/Group';

export default {
    components: { Group },
    props: {
        groupIndices: {
            type: Array,
            required: true
        },
        index: {
            type: Number,
            required: true
        }
    },
    inject: ['config'],
    computed: {
        groups() {
            return this.groupIndices.map(groupIndex => this.config.itemGroups[groupIndex]).reverse();
        }
    },
    methods: {
        gotoMemorize(group) {
            this.$router.push(`memorize/${group.itemIds[0]}`);
        }
    }
};
</script>

<style scoped lang="scss">
.SuperGroupPage {
    position: relative;
    display: flex;
    flex-direction: column;
}
.header {
    margin-top: 3em;
    flex-shrink: 0;
}
.header--empty {
    margin-top: 3em;
    flex-shrink: 0;
}
.body {
    flex: 1;
    overflow-y: auto;
    padding: 0 2em;

    display: flex;
    flex-direction: column;
    align-items: center;
}
.spacerTop {
    min-height: 1em;
    flex: 1;
}
.spacerBottom {
    min-height: 4em;
    flex: 1;
}
.verticalLine {
    min-height: 1em;
    height: 4em;
    width: 3px;
    background-color: rgba(white, 0.2);
}
</style>
