<template>
  <div
    v-if="loaded"
    class="LearnView">
    <SimpleVerticalCarousel
      :index="currentSuperGroupIndex"
      :items="superGroupConfs"
      :reversed="true"
      @request-index="index => (currentSuperGroupIndex = index)" />

    <div class="header">
      <div
        class="exitButton --global-clickable"
        @click="$router.push('home')">
        Exit
      </div>
    </div>
  </div>
</template>

<script>
import SimpleVerticalCarousel from '@/modules/games-shared/components/SimpleVerticalCarousel';

import SuperGroupPage from '../components/SuperGroupPage';

export default {
    components: { SimpleVerticalCarousel },
    inject: ['config', 'module'],
    data() {
        return {
            currentSuperGroupIndex: 0
        };
    },
    computed: {
        superGroups() {
            return this.config.superGroups || [{ index: 0, groupIndices: this.groups.map((g, i) => i) }];
        },
        groups() {
            return this.config.itemGroups;
        },
        superGroupConfs() {
            return this.superGroups.map(superGroup => {
                return {
                    component: SuperGroupPage,
                    props: superGroup
                };
            });
        },
        loaded() {
            return this.module.state.loaded;
        }
    },
    watch: {
        loaded: {
            immediate: true,
            handler(loaded) {
                if (loaded) {
                    const focusedGroupIndex = this.groups.findIndex((g, i) => this.module.getters('getGroupFocus')(i));
                    const focusedSuperGroupIndex = this.superGroups.findIndex(
                        superGroup => superGroup.groupIndices.indexOf(focusedGroupIndex) !== -1
                    );
                    if (focusedSuperGroupIndex !== -1) {
                        this.currentSuperGroupIndex = focusedSuperGroupIndex;
                    }
                }
            }
        }
    },
    methods: {
        gotoMemorize(group) {
            this.$router.push(`memorize/${group.itemIds[0]}`);
        }
    }
};
</script>

<style scoped lang="scss">
.LearnView {
    position: relative;
}
.header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    pointer-events: none;

    display: flex;
}
.exitButton {
    padding: 1em;
    color: rgba(white, 0.5);
    pointer-events: all;
}
.SuperGroupPage {
    height: 100%;
}
.SimpleVerticalCarousel {
    height: 100%;
}
</style>
