<template>
  <div
    class="Group"
    :class="{ locked, hasFocus }">
    <div
      class="shape --global-clickable"
      @click="!locked && $emit('click')" />
    <div class="label">
      {{ label }}
    </div>
    <!-- <div v-if="completed" class="completionIndicator"></div> -->
  </div>
</template>

<script>
export default {
    props: {
        label: String,
        index: Number
    },
    inject: ['module'],
    computed: {
        progress() {
            return this.module.getters('getGroupProgress')(this.index);
        },
        hasFocus() {
            return this.module.getters('getGroupFocus')(this.index);
        },
        locked() {
            return this.module.getters('getGroupLocked')(this.index);
        },
        completed() {
            return this.progress === 1;
        }
    }
};
</script>

<style scoped lang="scss">
$size: 8em;
.Group {
    position: relative;
    flex-shrink: 0;
    color: black;

    width: $size;
    height: $size;

    display: flex;
    justify-content: center;
    align-items: center;

    &.locked {
        opacity: 0.3;

        .shape {
            cursor: default;
        }
    }
    &.hasFocus {
        .shape {
            box-shadow: 0 0 0em 0.6em rgba(white, 0.4);
            animation: pulseFocus 2s infinite;

            @keyframes pulseFocus {
                0% {
                    box-shadow: 0 0 0em 0.3em rgba(white, 0.1);
                }
                50% {
                    box-shadow: 0 0 0em 0.6em rgba(white, 0.4);
                }
                100% {
                    box-shadow: 0 0 0em 0.3em rgba(white, 0.1);
                }
            }
        }
    }
}

.shape {
    position: absolute;
    $size: $size / 1.39; // 1.4142;//5em;
    width: $size;
    height: $size;
    background-color: white;
    border-radius: 0.5em;
    transform: rotate(45deg);
    box-shadow: 0 0 1em rgba(black, 0.1);
}

.label {
    position: relative;
    color: rgba(black, 0.7);
    font-weight: 600;
    font-size: 120%;
    pointer-events: none;
}

.completionIndicator {
    $size: 1em;
    width: $size;
    height: $size;
    border-radius: 50%;
    background-color: green;

    position: absolute;
    top: 0;
    right: 0;
    pointer-events: none;
}
</style>
